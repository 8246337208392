import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";

import LocationImgSlider from "../components/slider-location-img";
// import AccomodationSlider from "../components/slider-accomodation";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Location = ({ data }) => {
  // const { accomodation, duomo } = data;
  const { duomo } = data;

  return (
    <Layout>
      <Seo
        title={`Location`}
        description={`Magna Pars Event Space: un luogo storico nel cuore di Milano.`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/location.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>
                    Magna Pars, l'Hotel à Parfum si trova nella Milano dei Navigli, a pochi passi
                    dalla storica Stazione di Porta Genova.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="magna-pars" className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">Magna Pars Event Space</h2>
                <div className="box-article__text">
                  <p>
                    Il Magna Pars Event Space si trova nel centro del distretto della moda, in Via
                    Tortona 15, e si sviluppa al piano terreno di una palazzina dei primi del
                    Novecento.
                  </p>
                  <p>
                    La struttura, originariamente sede di una fabbrica di profumi, è stata
                    riconvertita in struttura polifunzionale nel 1991, mantenendo le caratteristiche
                    dell'edificio postindustriale, reinterpretate in chiave raffinata e
                    contemporanea.
                  </p>
                  <div className="d-flex justify-content-between align-items-center pt-3">
                    <div>
                      <strong>
                        <a
                          href="https://www.magnapars.it/eventi/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Magna Pars Event Space"
                        >
                          Magna Pars Event Space
                        </a>
                      </strong>
                      <br />
                      <span className="font-seconday font-small">
                        Piazza Mercanti, 2 20121 Milano (Italia)
                      </span>
                    </div>
                    <Link to="/come-arrivare/" className="btn-link btn-link--small">
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Come <span className="d-block bold">arrivare</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GatsbyImage
            image={getImage(duomo)}
            alt="img duomo"
            objectPosition="center center"
            className="box-article__img box-article__img--duomo d-none d-xl-block"
          />
        </div>

        <LocationImgSlider />
      </section>

      {/* <AccomodationSlider items={accomodation} /> */}

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Location;

export const query = graphql`
  query {
    accomodation: accomodationJson {
      accomodation {
        title
        stars
        distance
        phone
        email
        site
        address
        cap
        city
        linkmap
        image {
          childImageSharp {
            gatsbyImageData(width: 175, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
    duomo: file(relativePath: { eq: "img-duomo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 338, layout: CONSTRAINED)
      }
    }
  }
`;
